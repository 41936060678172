@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container {
    width: 100vw;
    /*height: 100%;*/
    overflow: hidden;
}

.top {
    background-color: #155263;
    font-family: 'Anek Telugu', sans-serif;
    font-weight: 500;
    padding: 0;
}

.pages {
    display: flex;
    width: 300%;
}

.page {
    width: 100vw;
    /*height: 100vh;*/
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    flex-direction: column;
    gap: 10px;
    transition: all 0.7s;
    color: #000000;
    font-weight: 400;
    padding: 2em;
    font-family: 'Libre Baskerville', serif;
}

.page > h3 {
    font-weight: 700;
}

.achterglas {
    background-color: #ff6f3c;
}

.blekejet {
    background-color: #ff9a3c;
}

.kecks {
    background-color: #ffc93c;
}

button {
    border: 0;
    width: 33.33%;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
}

button:nth-child(2) {
    background-color: #ff6f3c;
}

button:nth-child(3) {
    background-color: #ff9a3c;
}

button:nth-child(4) {
    background-color: #ffc93c;
}

.titel {
    width: 95%;
    font-size: 1.2em;
    background-color: #155263;
    text-align: right;
    color: white;
}

.youtubecontainer {
    margin: 1em auto;
    clear: both;
    display: inline-block;
}

@media screen and (max-width: 640px) {
    .youtubecontainer {
        width: 80vw;
    }

    button>.kort {
        display: inline;
    }
    button>.lang {
        display:none;
    }
}

@media screen and (min-width: 641px) {
    .youtubecontainer {
        width: 50vw;
    }

    button>.kort {
        display: none;
    }
    button>.lang {
        display:inline;
    }
}

